@import '../../../../styles/variables.scss';
@import '../../../../styles/typography.scss';
@import '../../../../styles/layout.scss';

.LinkDropdown {

    @extend .h9;
    color: $gold;

    position: absolute;
    top: auto;
    bottom: 0;
    left: $header-item-padding - $header-dropdown-padding;
    padding: $header-dropdown-padding;
    padding-top: $header-dropdown-padding * 0.65;
    padding-bottom: $header-dropdown-padding;
    min-width: 224px;

    border: 2px solid $gold;
    background: $cream;
    box-shadow: 2px 2px 5px 2px $shadow;

    transform: translateY(100%);
    z-index: 10;

    transition: visibility 0s, opacity 0.2s ease-in-out;
    visibility: hidden;
    opacity: 0;

    & > * {
        cursor: pointer;

        &:hover {
            color: $blue;
        }
    }

}

.LinkDropdown .MenuLink {

    display: flex;
    align-items: center;  
    line-height: 18px;
    margin-bottom: 15px;  

    &.anchor div::before {
        content: '>';
        margin-right: 10px;
        line-height: 1;
    }

}


.LinkDropdown.visible {

    visibility: visible;
    opacity: 1;

    .MenuLink {
        animation: 0.5s ease-in-out 1 menu-item-in;
        animation-fill-mode: forwards;

        opacity: 0;
    }

}

.LinkDropdown .MenuLink.secondary {
    padding-left: 20px;
}

@for $i from 1 through 100 {
    .LinkDropdown .MenuLink:nth-child(#{$i}) {
        animation-delay: $i * 0.05s;
    }
}

@keyframes menu-item-in {
    from {
        opacity: 0;
        transform: translateX(-15px);
    }
    to {
        opacity: 1;
        transform: translateX(0px);
    }
}





.App.theme-blue {
    .LinkDropdown > *:hover {
        color: $blue;
    }
}

.App.theme-grey {
    .LinkDropdown > *:hover {
        color: $grey;
    }
}

.App.theme-red {
    .LinkDropdown > *:hover {
        color: $red;
    }
}
