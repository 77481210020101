@import '../../styles/variables.scss';
@import '../../styles/typography.scss';
@import '../../styles/layout.scss';

.Header {

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: $header-height;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    border-bottom: 2px solid $gold;

    z-index: 1000;

    & + * {
        padding-top: $header-height;
    }

    & > *:not(.HeaderSubmenu),
    & > *:not(.HeaderTagOverlay) {
        background: $cream;
    }

}

.Header.collapsed {

    height: $header-collapsed-height;
    border-bottom: none;

    & + * {
        padding-top: $header-collapsed-height;

        @include desktop {
            padding-top: 300px;
        }
    }

}
